import React from "react";
import {useState, useEffect} from 'react';
import About from "./components/About";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Blog from "./components/Blog"
import Testimonials from "./components/Testimonials";

export default function App() {

  
  return (
    <main className="text-gray-400 
    bg-gray-900 
    body-font">
      <Navbar />
      {/* <a class="twitter-timeline" href="https://twitter.com/BrantlyMillegan?ref_src=twsrc%5Etfw">Tweets by CocktailCoder</a> */}
      <About />
      <Projects />
      <Blog />
      <Skills />
      {/* <Testimonials /> */}
      <Contact />

    </main>
  );
}